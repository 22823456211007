import React from "react";
import styled from "styled-components";
import MagicLink from "../MagicLink";
import Row from "../Row";
import { Font, media, rem, Color } from "../../utils/style";
import { Icons } from "../../utils/svg";
import Container from "../Container";
import { calculateReadingTime } from "../../utils/readingTime";
import Text from "../Text";

const QuoteContent = styled.div.attrs({
  className: "content",
})`
  padding: 60px 100px;
  border: 4px solid ${Color.ritualBlue};
  margin-top: 50px;
  margin-bottom: 50px;
  cursor: pointer;
  transition: all 0.3s;

  ${media.mobile`
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 40px 24px;
    border-width: 2px;
  `};

  &:hover {
    background-color: ${Color.ritualBlue};
    color: ${Color.white};

    * {
      text-decoration: none !important;
      color: ${Color.white};
    }

    svg {
      g {
        fill: ${Color.white};
      }
    }

    .fact-link {
      background: ${Color.white};

      span {
        color: ${Color.ritualBlue} !important;
      }
    }
  }
`;

const QuoteReadingtime = styled.div.attrs({
  className: "col-4",
})`
  ${Font.circular};
  color: ${Color.ritualBlue};
  font-size: ${rem(17)};
  line-height: ${rem(28)};
  font-weight: 400;
  text-align: right;
  margin: 0 0 8px;

  ${media.mobile`
    color: ${Color.ritualBlue};
    font-size: ${rem(16)};
    line-height: ${rem(24)};

    span {
      display: none;
    }
  `};
`;

const QuoteTitle = styled.h2.attrs({
  className: "col-8",
})`
  ${Font.circular};
  color: ${Color.ritualBlue};
  font-size: ${rem(17)};
  line-height: ${rem(28)};
  font-weight: 400;
  margin: 0 0 32px;

  ${media.mobile`
    color: ${Color.ritualBlue};
    font-size: ${rem(16)};
    line-height: ${rem(24)};
    font-weight: 400;
    letter-spacing: 0;
  `};
`;

const Quote = styled.div.attrs({
  className: "col-12",
})`
  p {
    ${Font.circular};
    color: ${Color.ritualBlue};
    font-size: ${rem(34)};
    line-height: ${rem(40)};
    font-weight: 400;
    text-align: left;
    letter-spacing: -0.5px;
    margin: 0;
    display: inline;

    &:before {
      content: "“";
    }

    &:after {
      content: "”";
    }

    i,
    em {
      ${Font.dutch};
      font-style: italic;
      font-weight: 300;
    }

    ${media.mobile`
      font-size: ${rem(22)};
      line-height: ${rem(30)};
      letter-spacing: -0.2px;
    `};
  }

  svg {
    width: 28px;
    height: auto;
    transform: translateY(-7px);
    margin-left: 8px;

    ${media.mobile`
      width: 18px;
      height: 8px;
      transform: translateY(-3px);
    `};
  }
`;

export default class FeaturedQuote extends React.Component {
  render() {
    const { quoteTitle, quote, articles } = this.props.data;

    if (!articles || !articles[0]) return null;

    const linkedArticle = articles[0];

    const readTime = calculateReadingTime(linkedArticle.primaryContent);

    const quoteHtml = quote.childMarkdownRemark.html;

    return (
      <MagicLink to={linkedArticle} ariaLabel={linkedArticle.title}>
        <Container>
          <Row>
            <div className="col-12 col-lg-10 offset-lg-1">
              <QuoteContent>
                <Row>
                  <QuoteTitle>{quoteTitle}</QuoteTitle>
                  <QuoteReadingtime>
                      <Text id="hub.featured-quote.read-time" defaultMessage="{readTime} min read" values={{ readTime }} />
                  </QuoteReadingtime>
                </Row>
                <Row>
                  <Quote>
                    <div>
                      <span dangerouslySetInnerHTML={{ __html: quoteHtml }} />
                      <Icons.ArrowRight />
                    </div>
                  </Quote>
                </Row>
              </QuoteContent>
            </div>
          </Row>
        </Container>
      </MagicLink>
    );
  }
}
