import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import $ from "jquery";
import ScrollMagic from "scrollmagic";

// Utils
import { rem, media, Font, Color } from "../../utils/style";

// Components
import Container from "../../components/Container";
import Row from "../../components/Row";
import PageSEO from "../../components/seo/Page";
import CategorySummary from "../../components/hub/CategorySummary";
import Featured2Up from "../../components/hub/Featured2Up";
import Featured3Up from "../../components/hub/Featured3Up";
import FeaturedLargeArticle from "../../components/hub/FeaturedLargeArticle";
import FeaturedFullWidth from "../../components/hub/FeaturedFullWidth";
import FeaturedQuote from "../../components/hub/FeaturedQuote";
import PageHero from "../../components/PageHero";

// Styled Elements
const HeroSection = styled.section`
  position: relative;
  background-color: ${p => p.background};
`;

const CategorySection = styled.section`
  padding-top: 100px;
  padding-bottom: 50px;
  border-bottom: 3px dotted #142b6f;

  &:last-of-type {
    border: none;
  }

  ${media.mobile`
    padding-top: 60px;
    padding-bottom: 30px;
  `};
`;

const CategoriesArea = styled.div``;

const SectionSummary = styled.div.attrs({
  className: "col-12 col-sm-6",
})`
  position: relative;
  margin-bottom: 50px;
  padding: 0 !important;

  ${media.mobile`
    padding: 0 20px !important;
  `}

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 80px;
    height: 4px;
    background-color: #E1E9F0;

    ${media.mobile`
      left: 20px;
    `}
  }

  p {
    ${Font.circular}
    color: ${Color.ritualBlue};
    font-size: ${rem(22)};
    line-height: ${rem(32)};
    letter-spacing: -0.2px;
    font-weight: 500;
    margin: 0;
    max-width: 470px;

    ${media.mobile`
      font-size: ${rem(16)};
      line-height: ${rem(26)};
      letter-spacing: 0;
    `}

    & > * {
      color: inherit;
      font-size: inherit;
      line-height: inherit;
    }
  }

  & > p {
    margin-bottom: ${rem(28)};
  }
`;

var Components = {
  "2-Up": Featured2Up,
  "3-Up": Featured3Up,
  Large: FeaturedLargeArticle,
  Quote: FeaturedQuote,
  "Full-Width": FeaturedFullWidth,
};

export default class Lab extends React.Component {
  constructor(props) {
    super(props);
    this.controller = null;
    this.categoriesArea = null;
    this.categorySummary = null;
    this.sections = [];
    this.state = {
      currentSection: 0,
      fixedNav: false,
      scrollActivation: true,
    };
  }

  componentDidMount() {
    this.props.updatePageData({
      label: "Blog",
    });

    this.props.updateLayoutState({
      navColor: "#FFFFFF",
      banner: false,
    });
    this.initializeScrollMagic();
    this.updateScrollMagicOnResize();
  }

  enableScrollActivation() {
    this.setState({
      scrollActivation: true,
    });
  }

  disableScrollActivation() {
    this.setState({
      scrollActivation: false,
    });
  }

  componentWillUnmount() {
    this.destroyScrollMagic();
  }

  updateScrollMagicOnResize() {
    $(window).on("resize.journal", this.debouncedUpdateScrollMagic.bind(this));
  }

  debouncedUpdateScrollMagic() {
    if (this.scrollMagicTimeout) {
      clearTimeout(this.scrollMagicTimeout);
    }
    this.scrollMagicTimeout = setTimeout(() => {
      this.updateScrollMagic();
    }, 200);
  }

  updateScrollMagic() {
    this.destroyScrollMagic();
    this.initializeScrollMagic();
  }

  destroyScrollMagic() {
    if (this.controller) {
      this.controller.destroy(true);
    }
    $(window).off("resize.journal");
  }

  initializeScrollMagic() {
    this.controller = new ScrollMagic.Controller({
      globalSceneOptions: {},
    }).scrollTo((newPosition, offset) => {
      $("HTML, BODY").animate(
        {
          scrollTop: newPosition - offset,
        },
        1500,
        () => {
          this.enableScrollActivation();
        },
      );
    });
    this.setFixedNavPastCategoriesArea();
    this.setCurrentSectionOnScroll();
  }

  setFixedNavPastCategoriesArea() {
    let headerOffset =
      ($("header").outerHeight() + $(".categorySelector").outerHeight()) * -1;
    new ScrollMagic.Scene({
      triggerElement: this.categoriesArea,
      offset: headerOffset - 16,
      triggerHook: 0,
    })
      .on("enter leave", e => {
        this.setState({
          fixedNav: ["DURING", "AFTER", "INSIDE"].includes(e.state),
        });
      })
      .addTo(this.controller);
  }

  setCurrentSectionOnScroll() {
    let _this = this;
    let headerOffset =
      ($("header").outerHeight() + $(".categorySelector").outerHeight()) * -1;
    this.sections.forEach((section, i) => {
      let sectionNode = $(`#section-${i}`);
      let scene = new ScrollMagic.Scene({
        triggerElement: sectionNode[0],
        triggerHook: 0,
        offset: headerOffset,
        duration: sectionNode.height(),
      }).on("enter", e => {
        if (_this.categorySummary && this.state.scrollActivation) {
          this.setState({
            currentSection: i,
          });
          _this.categorySummary.setActive(i);
        }
      });
      scene.addTo(this.controller);
    });
  }

  goToSection(index) {
    if (this.controller && this.categorySummary) {
      this.disableScrollActivation();
      this.categorySummary.setActive(index);
      this.controller.scrollTo($(`#section-${index}`)[0], 64);
    }
  }

  render() {
    let { contentfulPageFeatured } = this.props.data;
    let { currentSection, fixedNav } = this.state;
    let {
      slug,
      categorySections,
      desktopImage,
      mobileImage,
      heroBackgroundColor,
      openGraphImage,
      pageTitle,
      pageDescription,
    } = contentfulPageFeatured;

    let seo = {
      pagePath: slug,
      title: pageTitle,
      description: pageDescription,
      image: {
        src: openGraphImage.file.url,
        width: openGraphImage.file.details.image.width,
        height: openGraphImage.file.details.image.height,
      },
    };

    return (
      <>
        <PageSEO {...seo} />
        <HeroSection>
          <PageHero
            backgroundColor={heroBackgroundColor}
            imageMobile={mobileImage}
            imageDesktop={desktopImage}
          />
          <Container>
            <CategorySummary
              ref={r => {
                this.categorySummary = r;
              }}
              categorySections={categorySections}
              fixedNav={fixedNav}
              currentSection={currentSection}
              clickHandler={this.goToSection.bind(this)}
            />
          </Container>
        </HeroSection>
        <CategoriesArea
          ref={r => {
            this.categoriesArea = r;
          }}
        >
          {this.renderCategorySections(categorySections)}
        </CategoriesArea>
      </>
    );
  }

  renderCategorySections(categorySections) {
    return (
      categorySections &&
      categorySections.map((section, i) => {
        return (
          <CategorySection
            ref={r => {
              this.sections[i] = r;
            }}
            key={"category-section" + i}
            id={`section-${i}`}
          >
            <Container>
              <Row>
                <SectionSummary
                  dangerouslySetInnerHTML={{
                    __html: section.summary.childMarkdownRemark.html,
                  }}
                />
              </Row>
            </Container>
            {this.renderComponents(section.featuredArticles)}
          </CategorySection>
        );
      })
    );
  }

  renderComponents(featuredArticles) {
    return featuredArticles.map(function(article, i) {
      let component = Components[article.type];
      let nextArticle = featuredArticles[i + 1];
      let sameType = nextArticle ? nextArticle.type === article.type : false;
      let reactComponent = React.createElement(component, {
        data: article,
        sameAsNext: sameType,
        key: `featured-article_` + i,
      });
      return reactComponent;
    });
  }
}

export const pageQuery = graphql`
  query ContentPageQuery($locale: String!) {
    contentfulPageFeatured(node_locale: { eq: $locale }) {
      slug
      pageTitle
      pageDescription
      openGraphImage {
        file {
          url
          details {
            image {
              width
              height
            }
          }
        }
      }
      heroBackgroundColor
      desktopImage {
        file {
          url
        }
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      mobileImage {
        file {
          url
        }
        fluid(maxWidth: 912, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      categorySections {
        category {
          title
          slug
        }
        summary {
          childMarkdownRemark {
            html
          }
        }
        featuredArticles {
          __typename
          type
          alignment
          quoteTitle
          quote {
            childMarkdownRemark {
              html
            }
          }
          fullWidthImage {
            file {
              url
            }
            desktop: fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          articles {
            __typename
            slug
            title
            previewText
            heroBackgroundColor
            heroSecondaryColor
            heroImage {
              file {
                url
              }
              desktop: fixed(
                width: 960
                height: 560
                quality: 100
                cropFocus: LEFT
              ) {
                ...GatsbyContentfulFixed_withWebp_noBase64
              }
              mobile: fixed(
                width: 960
                height: 560
                quality: 100
                cropFocus: LEFT
              ) {
                ...GatsbyContentfulFixed_withWebp_noBase64
              }
            }
            ...expandPrimaryContent
          }
        }
      }
    }
  }
`;
