import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import MagicLink from "../MagicLink";
import MagicArrowCarousel from "../MagicArrowCarousel";
import { Font, media, Color, rem, maxLines } from "../../utils/style";
import ArticleReadtime from "./ArticleReadtime";
import Container from "../Container";
import Row from "../Row";

const ArticleCardContainer = styled.div`
  display: inline-block;
  vertical-align: top;
  white-space: normal;
`;

const Article = styled.div.attrs({ className: "" })`
  width: 340px;
  margin: 20px;

  &:hover {
    cursor: pointer;
  }

  ${media.notDesktop`
    width: 231px;
  `} span,
  p,
  h2 {
    ${media.mobile`
      color: ${p => (p.color ? p.color : Color.ritualBlue)};
    `};

    a {
      color: inherit;
    }
  }
`;

const ArticleImageWrapper = styled.div`
  height: 278px;
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: ${p => p.background};

  ${media.notDesktop`
    height: 174px;
  `} img {
    object-position: center center !important;
    transition: all 0.2s ease-in-out !important;
  }

  &:hover {
    img {
      transform: scale(1.1);
    }
  }

  ${media.mobile`
    &:hover img {
      transform: none;
    }
  `};
`;

const ArticleTitle = styled.h2`
  ${Font.circular}
  color: ${Color.ritualBlue};
  font-size: ${rem(22)};
  line-height: ${rem(30)};
  font-weight: 400;
  text-align: left;
  margin: 0 0 ${rem(16)};

  ${media.mobile`
    font-size: ${rem(24)};
    line-height: ${rem(32)};
  `}

  a {
    font: inherit;
    color: inherit;
    text-decoration: none;
  }
`;

const ArticleReadtimeElement = styled(ArticleReadtime)`
  margin-top: 27px;
`;

const ArticlePreview = styled.p`
  ${Font.dutch}
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  margin: 0;
  ${maxLines(24, 2)}
`;

export default class Featured3Up extends React.Component {
  renderArticles() {
    let { articles } = this.props.data;
    if (!articles.length) {
      return null;
    }
    return articles.map((article, i) => {
      if (!article) {
        return null;
      }
      return (
        <ArticleCardContainer key={`article-${i}`}>
          <MagicLink to={article} ariaLabel={article.title}>
            <Article>
              <ArticleImageWrapper background={article.heroBackgroundColor}>
                <Img
                  className="d-sm-none"
                  fixed={article.heroImage.mobile}
                  loading="eager"
                  color={article.heroBackgroundColor}
                  alt={article.title}
                  style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: "1",
                    userSelect: "none",
                    userDrag: "none",
                    pointerEvents: "none",
                    touchCallout: "none",
                  }}
                />
                <Img
                  className="d-none d-sm-block"
                  fixed={article.heroImage.mobile}
                  loading="eager"
                  color={article.heroBackgroundColor}
                  alt={article.title}
                  style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: "1",
                    userSelect: "none",
                    userDrag: "none",
                    pointerEvents: "none",
                    touchCallout: "none",
                  }}
                />
              </ArticleImageWrapper>
              <ArticleReadtimeElement article={article} />
              <ArticleTitle>{article.title}</ArticleTitle>
              <ArticlePreview>{article.previewText}</ArticlePreview>
            </Article>
          </MagicLink>
        </ArticleCardContainer>
      );
    });
  }

  render() {
    let articles = this.renderArticles();
    return (
      <Container>
        <Row>
          <MagicArrowCarousel arrowsEnabled={false} dotsEnabled={true}>
            {articles}
          </MagicArrowCarousel>
        </Row>
      </Container>
    );
  }
}
