import React from "react";
import styled from "styled-components";
import MagicLink from "../MagicLink";
import { Font, media, rem, maxLines, Color } from "../../utils/style";
import { graphql } from "gatsby";

const Content = styled.div.attrs({
  className: "content",
})`
  min-height: 427px;

  ${media.notDesktop`
    min-height: 340px;
  `} &:hover {
    * {
      text-decoration: none !important;
    }
  }
`;

const FactContent = styled(Content)`
  border: 4px solid ${Color.ritualBlue};
  padding: 50px;
  display: flex;
  align-items: flex-end;
  justify-content: left;
  transition: all 0.3s;

  * {
    transition: all 0.3s;
  }

  &:hover {
    background: ${Color.ritualBlue};
    color: white;

    * {
      color: white;
    }

    .fact-link {
      background: white !important;

      span {
        color: ${Color.ritualBlue} !important;
      }
    }
  }

  ${media.notDesktop`
    padding: 40px 24px;
    border-width: 2px;
  `};
`;

const Fact = styled.div``;

const FactTitle = styled.h2`
  ${Font.circular};
  color: ${Color.ritualBlue};
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  text-transform: uppercase;
  line-height: 24px;
  position: absolute;
  top: 60px;
  letter-spacing: 1px;
`;

const FactPreview = styled.p`
  ${Font.dutch};
  margin: 0 0 32px;
  color: ${Color.ritualBlue};
  font-size: ${rem(20)};
  line-height: ${rem(26)};
  font-weight: normal;
  text-align: left;
  max-width: 320px;
  ${maxLines(26, 3)};

  ${media.notDesktop`
    font-size: ${rem(16)};
    line-height: ${rem(24)};
    ${maxLines(24, 3)};
  `};
`;

const FactLink = styled(MagicLink).attrs({
  className: "btn btn--primary fact-link",
})`
  margin: 0;
`;

export default class Featured2UpFact extends React.Component {
  render() {
    let { title, bodyCopy, link, ctaText } = this.props;
    return (
      <FactContent>
        <Fact>
          <FactTitle>{title}</FactTitle>
          <FactPreview>{bodyCopy.bodyCopy}</FactPreview>
          <FactLink href={link}>
            <span>{ctaText}</span>
          </FactLink>
        </Fact>
      </FactContent>
    );
  }
}
