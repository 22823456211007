import React from "react";
import styled from "styled-components";
import MagicLink from "../MagicLink";
import Img from "gatsby-image";
import Row from "../Row";
import Container from "../Container";
import { Font, media, Color, rem, maxLines } from "../../utils/style";
import { graphql } from "gatsby";
import ArticleReadtime from "./ArticleReadtime";

const ArticleWrapper = styled.div`
  position: relative;
  overflow: hidden;
  margin-top: 50px;
  margin-bottom: 56px;
  background-color: ${p => p.background};

  ${media.mobile`
    margin: 15px 20px;
  `} img {
    transition: all 0.2s ease-in-out !important;
  }

  &:hover {
    cursor: pointer;
    * {
      text-decoration: none !important;
      transition: all 0.2s ease-in-out;
    }

    img {
      transform: scale(1.1);
    }
  }

  ${media.mobile`
    &:hover img {
      transform: none;
    }
  `};
`;

const ArticleContent = styled.div.attrs({
  className: "content",
})`
  display: flex;
  align-items: center;
  justify-content: left;
  min-height: 480px;

  ${media.mobile`
    min-height: 340px;
  `};
`;

const Article = styled.div.attrs({
  className: "col",
})`
  align-self: flex-end;
  max-width: 370px !important;
  padding: 0 0 ${rem(80)};

  ${media.mobile`
    padding: ${rem(24)};
  `} span,
  p,
  h2 {
    color: ${p => (p.color ? p.color : Color.ritualBlue)};

    a {
      color: inherit;
    }
  }
`;

const ArticleTitle = styled.h2`
  ${Font.circular}
  font-size: ${rem(22)};
  line-height: ${rem(30)};
  font-weight: 400;
  text-align: left;
  letter-spacing: -0.2px;
  margin: 0 0 16px;

  a {
    color: inherit;

    &:hover {
      color: inherit;
    }
  }

  ${media.mobile`
    font-size: ${rem(22)};
    line-height: ${rem(30)};
    letter-spacing: 0;
  `}
`;

const ArticlePreview = styled.p`
  ${Font.dutch}
  font-size: ${rem(17)};
  line-height: ${rem(28)};
  font-weight: normal;
  text-align: left;
  margin: 0;
  ${maxLines(30, 4)}

  ${media.mobile`
    font-size: ${rem(16)};
    line-height: ${rem(24)};
    ${maxLines(28, 4)}
  `}
`;

export default class FeaturedFullWidth extends React.Component {
  render() {
    let { article, fullWidthImage } = this.props.data;
    if (!article) {
      return null;
    }
    return (
      <MagicLink to={article} ariaLabel={article.title}>
        <ArticleWrapper background={article.heroBackgroundColor}>
          <Img
            className="d-sm-none"
            fixed={article.heroImage.mobile}
            loading="eager"
            color={article.heroBackgroundColor}
            alt={article.title}
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              userSelect: "none",
              userDrag: "none",
              pointerEvents: "none",
              touchCallout: "none",
            }}
          />
          <Img
            className="d-none d-sm-block"
            fluid={fullWidthImage.desktop}
            loading="eager"
            color={article.heroBackgroundColor}
            alt={article.title}
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              userSelect: "none",
              userDrag: "none",
              pointerEvents: "none",
              touchCallout: "none",
            }}
          />
          <Container>
            <Row>
              <ArticleContent>
                <Article color={article.heroSecondaryColor}>
                  <ArticleReadtime article={article} />
                  <ArticleTitle>{article.title}</ArticleTitle>
                  <ArticlePreview>{article.previewText}</ArticlePreview>
                </Article>
              </ArticleContent>
            </Row>
          </Container>
        </ArticleWrapper>
      </MagicLink>
    );
  }
}
