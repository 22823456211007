import React from "react";
import styled from "styled-components";
import Row from "../Row";
import { media } from "../../utils/style";
import Container from "../Container";
import Featured2UpArticle from "./Featured2UpArticle";
import Featured2UpFact from "./Featured2UpFact";
import { graphql } from "gatsby";

const Featured2UpRow = styled(Row)`
  margin-top: 50px;
  margin-bottom: 50px;

  ${media.tablet`
    margin-top: 0;
    margin-bottom: 0;
  `};
`;

const TwoUpBox = styled.div.attrs({ className: "col-12 col-md-6" })`
  ${media.tablet`
    &:first-child {
      margin: 30px auto 15px;
    }

    &:last-child {
      margin: 15px auto 30px;
    }
  `};
`;

export default class Featured2Up extends React.Component {
  render() {
    let { articles } = this.props.data;
    if (!articles) return null;

    if (articles.length > 2) articles.length = 2;
    return (
      <Container>
        <Featured2UpRow>
          {articles.map((article, i) => {
            return (
              <TwoUpBox key={i}>
                <Featured2UpArticle article={article} key={"article-" + i} />
              </TwoUpBox>
            );
          })}
        </Featured2UpRow>
      </Container>
    );
  }
}
