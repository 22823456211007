import React from "react";
import ScrollableOverflow from "../ScrollableOverflow";
import styled from "styled-components";
import { media, responsive, rem, Color, Font } from "../../utils/style";

const SummaryWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 15;
  transition: background 0.2s;

  display: flex;
  justify-content: center;

  overflow: hidden;
  padding: 8px 0 0;

  ${responsive.md`
    padding: 16px 0 0;
  `}

  &:not(.active) {
    bottom: 32px;
  }

  &.active {
    position: fixed;
    top: 48px;
    background-color: #e9eef1;
    border-bottom: 1px solid ${Color.white};
    transition: background 0.6s;

    ${responsive.md`
      top: 64px;
    `}

    .category-text {
      color: ${Color.ritualBlue};

      &.active {
        color: ${Color.ritualBlue};

        &:after {
          background-color: ${Color.ritualBlue};
        }
      }
    }
  }
`;

const CategoryButton = styled.div.attrs({
  className: "category-button",
})`
  display: inline-block;
  padding: 0 20px 8px;

  ${responsive.sm`
    padding: 0 50px 8px;
  `}

  ${responsive.md`
    padding: 0 50px 14px;
  `}

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const CategoryButtonText = styled.p.attrs({
  className: "category-text",
})`
  ${Font.circular}
  display: inline-block;
  color: ${Color.white};
  opacity: 0.56;
  font-size: ${rem(17)};
  font-weight: 400;
  text-align: left;
  line-height: ${rem(24)};
  padding-bottom: 2px;
  margin: 0;
  cursor: pointer;
  position: relative;
  transition: all 0.2s;

  &.active {
    color: ${Color.white};
    opacity: 1;

    &:after {
      position: absolute;
      left: 0;
      bottom: -8px;
      width: 100%;

      content: "";
      height: 4px;
      background-color: ${Color.white};

      ${responsive.md`
        bottom: -14px;
      `};
    }
  }

  ${media.mobile`
    font-size: ${rem(16)};
    line-height: ${rem(26)};
  `};
`;

export default class CategorySummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
    };
  }

  setActive(index) {
    this.setState(
      {
        index: index,
      },
      () => {
        if (this.scrollableContent) {
          this.scrollableContent.scrollToIndex(index);
        }
      },
    );
  }

  renderButtons() {
    let { categorySections, clickHandler } = this.props;
    let { index } = this.state;

    return categorySections.map((category_section, i) => {
      return (
        <CategoryButton
          className={index === i ? "active" : ""}
          key={"category-button-" + i}
        >
          <CategoryButtonText
            onClick={clickHandler.bind(this, i)}
            className={index === i ? "active" : ""}
          >
            {category_section.category.title}
          </CategoryButtonText>
        </CategoryButton>
      );
    });
  }

  render() {
    let { fixedNav } = this.props;

    return (
      <SummaryWrapper
        className={fixedNav ? "categorySelector active" : "categorySelector"}
      >
        <ScrollableOverflow
          bleedLeft={true}
          bleedRight={true}
          ref={r => {
            this.scrollableContent = r;
          }}
        >
          {this.renderButtons()}
        </ScrollableOverflow>
      </SummaryWrapper>
    );
  }
}
