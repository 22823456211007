import React from "react";
import styled from "styled-components";
import ScrollableOverflow from "./ScrollableOverflow";
import { rem, Color, Font, media } from "../utils/style";
import { Icons } from "../utils/svg";

const NumberWrapper = styled.div`
  width: 100%;
  margin: 40px auto 0;
  display: inline-block;
  text-align: center;
  font-size: ${rem(30)};

  display: flex;
  justify-content: center;
  align-items: center;

  ${media.mobile`
    margin: 20px auto 0;
  `};
`;

const NumberCounter = styled.span`
  ${Font.circular}
  color: ${Color.ritualBlue};
  font-size: ${rem(14)};
  line-height: ${rem(24)};
  font-weight: 500;
  margin: 0;
  text-align: center;
`;

const PageArrow = styled.button`
  display: block;
  transform: translateY(-2px);
  padding: 0 15px 5px;

  appearance: none;
  background: none;
  border: none;

  [data-whatintent="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }

  &:first-of-type {
    margin-right: 8px;
  }

  &:last-of-type {
    margin-left: 8px;
  }

  svg {
    g {
      stroke: ${p =>
        p.disabled ? Color.ritualSecondaryBlue : Color.ritualBlue};
    }
  }
`;

const DotsWrapper = styled.div`
  width: 100%;
  margin: 20px auto;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
`;

const Dot = styled.div`
  background-color: ${Color.ritualBlue};
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  background-color: ${props => (props.active ? Color.ritualBlue : "#9FAFC9")};

  &:not(:last-child) {
    margin-right: 6px;
  }
`;

export default class MagicArrowCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
    };

    this.scrollableRef = React.createRef();
  }

  handleIndexChange(index) {
    this.setState({
      index,
    });
  }

  onNeedsSlider(needsSlider) {
    this.setState({
      needsSlider: needsSlider,
    });
  }

  previous() {
    if (this.state.index === 0) {
      return;
    }
    const index = this.state.index - 1;
    this.setState(
      {
        index,
      },
      () => {
        this.scrollableRef.current.scrollToIndex(index);
      },
    );
  }

  next() {
    if (this.state.index === this.getTotalCount() - 1) {
      return;
    }

    const index = this.state.index + 1;
    this.setState(
      {
        index,
      },
      () => {
        this.scrollableRef.current.scrollToIndex(index);
      },
    );
  }

  getTotalCount() {
    return this.props.children.length;
  }

  pageNumbers() {
    const { counterEnabled } = this.props;
    const { index } = this.state;
    const totalCount = this.getTotalCount();

    const isFirst = index === 0;
    const isLast = index === totalCount - 1;

    return (
      <NumberWrapper>
        <PageArrow onClick={this.previous.bind(this)} disabled={isFirst}>
          <Icons.ArrowRoundedLeft />
        </PageArrow>
        {counterEnabled && (
          <NumberCounter>
            {index + 1} / {totalCount}
          </NumberCounter>
        )}
        <PageArrow onClick={this.next.bind(this)} disabled={isLast}>
          <Icons.ArrowRoundedRight />
        </PageArrow>
      </NumberWrapper>
    );
  }

  dots() {
    const { index } = this.state;
    return (
      <DotsWrapper>
        {this.props.children.map(function(c, i) {
          return <Dot active={index === i} key={`dot-${i}`} />;
        })}
      </DotsWrapper>
    );
  }

  render() {
    const { children, arrowsEnabled, dotsEnabled } = this.props;
    let { needsSlider } = this.state;

    return (
      <>
        <ScrollableOverflow
          ref={this.scrollableRef}
          onActiveIndexChange={this.handleIndexChange.bind(this)}
          onHasOverflow={this.onNeedsSlider.bind(this)}
        >
          {children}
        </ScrollableOverflow>
        {needsSlider && arrowsEnabled && this.pageNumbers()}
        {needsSlider && dotsEnabled && this.dots()}
      </>
    );
  }
}
